@import "minima";

.last-mod {
  font-size: 80%;
}

.img-responsive {
  max-height: 370px;
}

.partners {
  font-size: 90%;
}

a.active {
  font-weight: bold;
  color: #444;
}

@media print {
  body {
    font-size: 90%;
  }

  header.site-header {
    display: none;
  }

  .post-content h2 {
    font-size: 20px;
  }

  h1.post-title {
    font-size: 32px;
  }

  input[type="checkbox"] {
    outline: 1px solid #000;
  }
}
